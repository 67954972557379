<template lang="pug">
  .details-step
    .name-description-tags
      .filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Schema Preset Name
          .description.nio-p.text-primary-dark The displayed name of your schema preset.
        .filter-value
          NioTextField(
            v-model="model.name" 
            :rules="[validateName]"
            label="Name"
            validate-on-blur 
          )
      .filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Schema Preset Description
          .description.nio-p.text-primary-dark Information about your schema preset.
        .filter-value
          NioTextField(
            v-model="model.description" 
            label="Description"
          )
      .filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Tags
          .description.nio-p.text-primary-dark Used for categorization and discoverability across the platform. Use the return key to save a tag.
        .filter-value
          NioTagsField(
            v-model="model.tags" 
            label="Add Tags"
          )
      .filter.icon
        .title-description
          .filter-title.nio-h4.text-primary-darker Icon
          .description.nio-p.text-primary-dark Provide a URL for a display icon that will represent this schema preset. A generic placeholder will be displayed if no icon is provided.
        .filter-value
          NioTextField(
            v-model="model.icon"
            label="Icon URL"
          )
          .preview
            .nio-p.text-primary-dark {{ model.icon ? 'Icon Preview:' : 'Placeholder Icon:' }}
            NioImageTile(
              :src="model.icon ? model.icon : placeholderIcon"
              size="normal"
            )
      .filter.display-color
        .title-description
          .filter-title.nio-h4.text-primary-darker Display Color
          .description.nio-p.text-primary-dark The banner and accent color that will be used for this schema preset.
        .filter-value
          NioColorPicker(
            v-model="model.displayColor"
            label="Display Color"
          )
</template>

<script>

export default { 
  data: () => ({
    model: {
      filterable: [],
      deliverable: [],
      name: null,
      description: null,
      tags: [],
      icon: null,
      displayColor: '#0922A6'
    },
    placeholderIcon: 'https://cdn.narrative.io/data-studio/images/narrative-placeholder-primary.svg'
  }),
  watch: {
    model: {
      deep: true, 
      handler(val) {
        this.updatePayload()
      }
    }
  },
  methods: {
    payloadChanged(event) {
      this.filterable = event.filterable
      this.deliverable = event.deliverable
    },
    validateName(value) {
      if (!value || value.trim().length === 0) {
        return 'required'
      } else {
        return true
      }
    },
    updatePayload() {
      this.$emit('stepPayloadChanged', this.model)
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.details-step
  .name-description-tags
    width: 100%
    margin-top: 1rem
    margin-bottom: 1rem
    // border: 1px solid $c-primary-lighter
    // border-radius: 12px
    // padding: 24px
    .filter
      display: grid
      grid-template-columns: 1fr 1fr
      grid-gap: 1rem
      align-items: flex-start
      width: 100%
      border: 1px solid $c-primary-lighter
      border-bottom: 0px
      padding: 1rem

      .nio-text-field
        margin-bottom: 18px !important
      
      &:first-child
        border-radius: 12px 12px 0 0 

      &:last-child
        border-radius: 0 0 12px 12px
        border-bottom: 1px solid $c-primary-lighter
      
      .filter-value
        width: 100%
        .nio-text-field
          margin-bottom: 0
      &.icon
        .preview
          margin-top: -2px
          .nio-p
            margin-bottom: 5px
          img
            display: flex
            align-items: center
            height: 1.75rem
       
</style>
