<template lang="pug">
  .custom-schema-presets-page
    .app-loading(v-if="loading")
      v-progress-circular.progress(size="80" indeterminate color="#1438F5")
    .new-schema-preset(v-else-if="attributes")
      NioStepper(
        v-if="steps"
        :ordered-steps="steps"
        :current-step="currentStep"
        :completed-steps="completedSteps"
        final-step-label="Create Schema Preset"
        @nextStep="nextStep"
        @previousStep="previousStep"
        @submit="createSchemaPreset"
        @stepSelected="stepSelected($event)"
      )
        NioStep(
          v-if="steps.includes('details')"
          :valid="nameValid"
          :summary="makeStepSummary('details')"
          step-name="details"
          simple-summary
        )
          template(v-slot:content)
            NioDivider(horizontal-solo)
            DetailsStep(
              :current-step="currentStep"
              :completed-summary="makeStepSummary('details')"
              :completed-steps="completedSteps"
              @stepPayloadChanged="updatePayload('details', $event)"
              @setStepIncomplete="setStepIncomplete('details')"
            )
        NioStep(
          v-if="steps.includes('attributes')"
          :valid="attributesValid"
          :summary="makeStepSummary('attributes')"
          step-name="attributes"
          custom-summary
        )
          template(v-slot:content)
            NioDivider(horizontal-solo)
            AttributesStep(
              :attributes="attributes"
              :deliverable="stepPayloads.attributes ? deliverable : []"
              :filterable="stepPayloads.attributes ? filterable : []"
              @stepPayloadChanged="updatePayload('attributes', $event)"
              @setStepIncomplete="setStepIncomplete('attributes')"
            ) 
          template(v-slot:custom-summary)
            .attributes-summary
              .entry.deliverable
                .nio-h4.text-primary-darker {{ deliverable.length }} deliverable attribute{{ deliverable.length !== 1 ? 's' : ''}}
              .entry.filterable
                .nio-h4.text-primary-darker {{ filterable.length }} filterable attribute{{ filterable.length !== 1 ? 's' : ''}}   
    NioDialog(
      v-model="errorDialog" 
    )
      ErrorDialog(
        @close="errorDialog = false"
      )     
</template>

<script>

import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { getAttributeFromPath } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'
import DetailsStep from './steps/DetailsStep'
import AttributesStep from './steps/AttributesStep'
import ErrorDialog from './ErrorDialog'
import { makeDetailsObj } from './createSchemaPreset'

export default { 
  components: { DetailsStep, AttributesStep, ErrorDialog },
  props: {
    "attributes": { type: Array, required: true }
  },
  data: () => ({
    loading: false,
    errors: {
      name: true,
      deliverable: true
    },
    stepPayloads: {
      details: null,
      attributes: null
    },
    filterable: [],
    deliverable: [],
    steps: ['details', 'attributes'],
    currentStep: 'details',
    completedSteps: [],
    errorDialog: false
  }),
  computed: {
    nameValid() {
      return this.stepPayloads.details?.name?.trim().length > 0
    },
    attributesValid() {
      return this.deliverable.length > 0
    }
  },
  methods: {
    updatePayload(step, payload) {
      this.stepPayloads[step] = payload
      switch(step) {
        case 'details':
          break;
        case 'attributes':
          this.stepPayloads.attributes.deliverable = this.stepPayloads.attributes.deliverable.filter(path => {
            const parentAttribute = this.attributes.find(attribute => attribute.id === path[0].id)
            const attribute = getAttributeFromPath(path, parentAttribute)
            return attribute.type !== 'binary'
          })  
          this.filterable = this.stepPayloads.attributes.filterable.filter(path => {
            const parentAttribute = this.attributes.find(attribute => attribute.id === path[0].id)
            return getAttributeFromPath(path, parentAttribute).type !== 'object'
          })
          this.deliverable = this.stepPayloads.attributes.deliverable.filter(path => {
            const parentAttribute = this.attributes.find(attribute => attribute.id === path[0].id)
            return getAttributeFromPath(path, parentAttribute).type !== 'object'
          })
          break;
        default:
      }
    },
    nextStep() {
      if (!this.completedSteps.includes(this.currentStep)) {
        this.completedSteps.push(this.currentStep)
      }
      this.currentStep = this.steps[this.steps.indexOf(this.currentStep) + 1]
      this.scrollToStep(this.steps.indexOf(this.currentStep))
    },
    previousStep() {
      this.currentStep = this.steps[this.steps.indexOf(this.currentStep) - 1]
      this.scrollToStep(this.steps.indexOf(this.currentStep))
    },
    stepSelected(stepName) {
      this.currentStep = stepName
    },
    setStepIncomplete(stepName) {
      const stepIndex = this.completedSteps.indexOf(stepName)
      this.completedSteps = this.completedSteps.filter((step, index) => {
        index < stepIndex
      })
      this.steps.map((step, index) => {
        if (index >= stepIndex) {
          this.stepPayloads[step] = null
        }
      })
    },
    validate() {
      if (this.model.name?.length > 0) {
        this.errors.name = false
      } else {
        this.errors.name = true
      }
      if (this.deliverable.length > 0) {

      }
    },
    scrollToStep(stepIndex) { 
      this.$nextTick(() => {
        const top = 35 + stepIndex * 130
        parent.postMessage({
          name: 'scrollTo',
          payload: {
            x: 0,
            y: top
          }
        },"*")
      })       
    },
    makeStepSummary(stepName) {
      if (!this.stepPayloads[stepName]) {
        return
      }
      switch (stepName) {
        case 'details':
          return {
            title: `${this.stepPayloads.details.name}`
          }
        case 'attributes':
          return {
            title: `${this.stepPayloads.details.name}`
          }
        default:
      }
    },
    createSchemaPreset() {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 80
        }
      },"*")
      const details = this.stepPayloads.details
      const deliverable = this.deliverable
      const filterable = this.stepPayloads.attributes.filterable ? this.filterable : [] 
      const request = {
        name: details.name,
        tags: details.tags?.length > 0 ? details.tags : [],
        
        display_color: details.displayColor,
        details: {
          attributes: makeDetailsObj(this.attributes, filterable, deliverable)
        }
      }
      if (details.description) request.description = details.description
      if (details.icon) request.icon = details.icon
      this.loading = true
      this.$nioOpenApi.post(`/schema-presets`, JSON.stringify(request)).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.loading = false
          this.deliverable = []
          this.filterable = []
          this.stepPayloads = {
            details: {
              filterable: [],
              deliverable: []
            },
            attributes: {
              filterable: [],
              deliverable: [],
              name: null,
              description: null,
              tags: [],
              icon: null,
              displayColor: '#0922A6'
            }
          }
          this.currentStep = 'details'
          this.completedSteps = [] 
          this.$emit('customPresetCreated')
        } else {
          this.loading = false
          this.errorDialog = true
        }
      }, err => {
        this.loading = false
        this.errorDialog = true
      })
    } 
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.custom-schema-presets-page
  background-color: $c-white
  padding: 24px 0px 0px 0px !important
  .header
    display: flex
    justify-content: flex-start
    align-items: flex-end
    position: relative
    margin-bottom: 2rem
    height: 2.25rem
    h1
      line-height: 1.75rem
    .nio-button
      position: absolute
      right: 0rem  
  h2
    text-align: center
    margin: 3rem auto 1.5rem auto
  .no-schema-presets
    padding: 9.6875rem 1.5rem 11.1875rem 1.5rem
    background-color: $c-canvas
    border: 0.0625rem solid $c-primary-lighter
    border-radius: 0.75rem
    display: flex
    flex-direction: column
    align-items: center
    .nio-icon-framer
      margin-bottom: 1rem
    h3
      margin-bottom: 0.5rem
    p    
      margin-bottom: 2.5rem
  ::v-deep .nio-step-content
    padding-left: 0rem !important
    padding-right: 0rem !important
</style>
