let deepCopy = (val) => JSON.parse(JSON.stringify(val))

function makeDetailsObj(attributes, filterable, deliverable) {
	const uniqueAttributeNames = []
	const results = []

	filterable.forEach(filterableProperty => {
		if (!uniqueAttributeNames.includes(filterableProperty[0].name)) {
			uniqueAttributeNames.push(filterableProperty[0].name)
		}
	})
	deliverable.forEach(deliverableProperty => {
		if (!uniqueAttributeNames.includes(deliverableProperty[0].name)) {
			uniqueAttributeNames.push(deliverableProperty[0].name)
		}
	})

	uniqueAttributeNames.forEach(attributeName => {
		const targetAttribute = attributes.find(attribute => attribute.name === attributeName)

		const resultObj = {
			attribute_id: targetAttribute.id,
			fields: []
		}

		const allPaths = [...filterable, ...deliverable].filter(path => path[0].name === attributeName && (path.length > 1 || targetAttribute.type !== 'object'))
		
		const deliverablePathStrings = deliverable.filter(path => path[0].name === attributeName).map(path => {
			const pathCopy = deepCopy(path)
			pathCopy.shift()
			pathCopy.unshift(path[0].name)
			return pathCopy.join('.')
		}).filter(pathString => pathString.length > 1 || targetAttribute.type !== 'object')

		const filterablePathStrings = filterable.filter(path => path[0].name === attributeName).map(path => {
			const pathCopy = deepCopy(path)
			pathCopy.shift()
			pathCopy.unshift(path[0].name)
			return pathCopy.join('.')
		}).filter(pathString => pathString.length > 1 || targetAttribute.type !== 'object')
		
		allPaths.forEach(path => {
			const pathCopy = deepCopy(path)
			pathCopy.shift()
			pathCopy.unshift(path[0].name)
			const pathString = pathCopy.join('.')
			if (!resultObj.fields.find(field => field.field === pathString)) {
				resultObj.fields.push({
					field: pathString,
					delivered: deliverablePathStrings.includes(pathString),
					filterable: filterablePathStrings.includes(pathString),
					forecastable: false,
					required: filterablePathStrings.includes(pathString)
				})
			}
		})
		results.push(resultObj)
	})
	return results
}

export { makeDetailsObj }